<template>
  <section class="base-users-list">
    <h3 class="base-users-list__title" v-html="title"></h3>
    <users-list-tabs
      v-if="showTabs"
      :tabs-list="tabsList"
      class="base-users-list__tabs"
      @tabchange="catchTabChange"
    ></users-list-tabs>
    <p class="base-users-list__sorting-note">{{ $t("Sorted by relevancy descending") }}</p>
    <div class="base-users-list__content">
      <users-list
        :quotations="quotationsFilteredList"
        :ticket-data="ticketData"
        :selectionComplete="isSelectionComplete"
        @purchaseorder="$emit('purchaseorder')"
      ></users-list>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import BaseUsersListSection from "@/components/OrderUsersList/BaseUsersListSection";
import UsersListTabs from "@/components/OrderUsersList/UsersListTabs";

export default {
  name: "QuotationsList",
  extends: BaseUsersListSection,
  components: {
    "users-list-tabs": UsersListTabs
  },
  data() {
    return {
      activeTab: "experts"
    };
  },
  computed: {
    ...mapGetters("UsersListsStore", ["experts", "technicians", "selectedQuotes"]),
    quotationsFilteredList() {
      return this[this.activeTab];
    },
    isSelectionComplete() {
      return this.selectedQuotes.length === 2;
    },
    showTabs() {
      return this.experts.length && this.technicians.length;
    },
    tabsList() {
      return [
        {
          id: "experts",
          title: this.$t("Experts")
        },
        {
          id: "technicians",
          title: this.$t("Technicians")
        }
      ];
    }
  },
  methods: {
    catchTabChange(tab) {
      this.activeTab = tab;
    }
  }
};
</script>

<style scoped>
.base-users-list {
  display: block;
  width: 100%;
}
.base-users-list__title {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: normal;
  border-bottom: 1px solid #dfdfdf;
}
.base-users-list__tabs {
  text-align: center;
}
.base-users-list__sorting-note {
  display: block;
  width: 100%;
  padding-right: 40px;
  margin-bottom: 0;
  font-size: 18px;
  text-align: right;
}
.base-users-list__content {
  display: block;
  width: 100%;
  margin-bottom: 60px;
  padding: 10px;
}
</style>
