<template>
  <section class="users-list">
    <v-window v-model="view" class="pa-4">
      <v-window-item :value="'list'">
        <ul class="users-list__list" v-if="view === 'list'">
          <li v-for="user in quotations" :key="user.id" class="users-list__user">
            <item-actions
              mode="list"
              :user="user"
              :ticket-data="ticketData"
              :selection-complete="selectionComplete"
              @purchaseorder="$emit('purchaseorder')"
            >
              <v-btn
                color="primary"
                :outlined="!user.reviewed"
                :disabled="selectionComplete"
                @click="open(user.quote_request_id)"
                >{{ $t("Open") }}</v-btn
              >
            </item-actions>
          </li>
        </ul>
        <div v-if="quotations.length > 15" class="users-list__pagination-cont">
          <v-pagination
            :length="totalPages"
            :total-visible="5"
            :value="currantPage"
            class="users-list__pagination"
          />
        </div>
      </v-window-item>
      <v-window-item :value="'one'">
        <item-actions
          mode="one"
          :user="one"
          :ticket-data="ticketData"
          :selection-complete="selectionComplete"
          @purchaseorder="$emit('purchaseorder')"
        >
        </item-actions>
        <v-btn
          color="primary"
          class="mt-4 float-right"
          small
          outlined
          @click="back"
          v-if="one && !one.purchase_order_doc"
          >{{ $t("Back to the list") }}</v-btn
        >
      </v-window-item>
    </v-window>
  </section>
</template>

<script>
import ItemActions from "@/components/OrderUsersList/ItemActions";
// import { mapState, mapMutations } from "vuex";
// import OneItem from "@/components/OrderUsersList/OneUserItem";
// import PurchaseOrderSt1 from "@/components/ContractModals/Modals/PurchaseOrderSt1";
// import PurchaseOrderSt2 from "@/components/ContractModals/Modals/PurchaseOrderSt2";
// import { isPlainObject, isArray } from 'lodash';

export default {
  name: "users-list",
  props: {
    quotations: [Array, Object],
    ticketData: Object,
    selectionComplete: Boolean
  },
  components: {
    "item-actions": ItemActions
  },
  // mounted() {

  // },
  // computed: {
  //   one() {
  //     return this.ticketData.quote_request ? this.quotations : null;
  //   },
  //   view() {
  //     return this.ticketData.quote_request ? "one" : "list";
  //   }
  // },
  watch: {
    quotations() {
      if (this.ticketData.quote_request) {
        // debugger;
        this.view = "one";
        this.one = this.quotations.find(
          i => this.ticketData.quote_request.quote_request_id === i.quote_request_id
        );
      } else {
        this.view = "list";
        this.one = null;
      }
    }
  },

  data() {
    return {
      totalPages: 19,
      currantPage: 1,
      one: null,
      view: "list"
    };
    // },
    // computed: {
    //   ...mapState("PurchaseOrderSt1ContractModal", {
    //     isModalOpenedPurchaseOrderSt1: state => state.isModalOpened
    //   }),
    //   ...mapState("PurchaseOrderSt2ContractModal", {
    //     isModalOpenedPurchaseOrderSt2: state => state.isModalOpened
    //   }),
    //   quotationsList() {
    //     // TODO: back wtf
    //     if (isPlainObject(this.quotations)) {
    //       return [this.quotations];
    //     }

    //     return this.quotations || [];
    //   }
    // },
    // methods: {
    //   ...mapMutations({
    //     // step 1
    //     openPurchaseStep1Modal: "PurchaseOrderSt1ContractModal/openModal",
    //     closePurchaseStep1Modal: "PurchaseOrderSt1ContractModal/closeModal",
    //     // step 2
    //     openPurchaseStep2Modal: "PurchaseOrderSt2ContractModal/openModal"
    //   }),
    //   switchStep2PurchaseModal() {
    //     this.closePurchaseStep1Modal();
    //     this.openPurchaseStep2Modal();
    //   }
  },

  methods: {
    open(id) {
      this.view = "one";
      this.one = this.quotations.find(i => id === i.quote_request_id);
    },
    back() {
      this.view = "list";
      this.one = null;
    }
  }
};
</script>

<style>
.users-list__pagination .v-pagination__item,
.users-list__pagination .v-pagination__navigation {
  height: auto;
  min-width: initial;
  padding: 0 5px;
  font-size: 20px;
  box-shadow: none;
  border-radius: 12px;
}
</style>

<style scoped>
.users-list {
  display: block;
  width: 100%;
  padding: 10px;
}
.users-list__list {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.users-list__user {
  margin-bottom: 35px;
}
.users-list__user:last-child {
  margin-bottom: 0;
}
.users-list__pagination-cont {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
}
</style>
