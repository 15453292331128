<template>
  <v-container fluid>
    <v-row class="mb-4">
      <v-col>
        <v-btn-toggle
          class="styled-btn-group"
          v-model="activeBtn"
          rounded
          mandatory
          active-class="active-tab-btn"
          @change="$emit('tabchange', activeBtn)"
        >
          <v-btn
            v-for="tab in tabsList"
            :key="tab.id"
            :outlined="activeBtn !== tab.id"
            :value="tab.id"
            color="primary"
            v-html="tab.title"
          ></v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UsersListTabs",
  props: {
    activeTab: String,
    tabsList: Array
  },
  data() {
    return {
      activeBtn: this.activeTab
    };
  }
};
</script>
