<template>
  <list-item
    :user-data="user"
    :ticket-data="ticketData"
    :show-second-layer="showSecondLayer"
    :show-third-layer="showThirdLayer"
  >
    <!--  QUOTE ACTIONS  -->
    <template v-slot:quote-actions>
      <!--  Started  -->
      <template v-if="user.aasm_state === 'started' || user.aasm_state === 'declined'">
        <v-btn
          outlined
          color="primary"
          class="mr-4"
          @click="viewQuotationRequest(user.quote_request_id)"
        >
          {{ $t("View quotation") }}
        </v-btn>

        <slot></slot>
        <v-btn color="primary" v-if="mode === 'one'" @click="openPurchaseStep1Modal">
          {{ $t("Send contract") }}
        </v-btn>
      </template>
      <!--  /Started  -->

      <!--  Selected  -->
      <template v-if="user.aasm_state === 'selected'">
        <v-btn outlined color="primary" class="mr-4" @click="replaceUser(user.quote_request_id)">
          {{ $t("Replace applicant") }}
        </v-btn>
      </template>
      <!--  /Selected  -->

      <!--  Offered  -->
      <template v-if="user.aasm_state === 'offered'">
        <v-btn outlined color="primary" class="mr-4" @click="replaceUser(user.quote_request_id)">
          {{ $t("Replace applicant") }}
        </v-btn>
      </template>
      <!--  /Offered  -->

      <!--  Signed  -->
      <template v-if="user.aasm_state === 'requested_by_type' && !user.purchase_order_doc">
        <v-btn outlined color="primary" @click="withdrawPO(user.quote_request_id)" class="mr-4">
          {{ $t("Withdraw PO") }}
        </v-btn>
      </template>
      <!--  /Signed  -->

      <!--  Done  -->
      <template v-if="user.aasm_state === 'done' && !isOnWarranty">
        <v-btn color="primary" class="mr-4">{{ $t("View report") }}</v-btn>
        <v-btn color="primary">{{ $t("Confirm report") }}</v-btn>
      </template>
      <!--  /Done  -->

      <!--  Done(on warranty)  -->
      <template v-if="user.aasm_state === 'done' && isOnWarranty">
        <v-btn color="primary">{{ $t("Request warranty") }}</v-btn>
      </template>
      <!--  /Done(on warranty)  -->
    </template>

    <!--  INVOICE ACTIONS  -->
    <template v-slot:invoice-actions>
      <template v-if="user.aasm_state === 'done'">
        <v-btn outlined color="primary" class="mr-4">{{ $t("View invoice") }}</v-btn>
        <v-btn color="primary">{{ $t("Pay invoice") }}</v-btn>
      </template>
    </template>

    <!--  QUOTE MODALS  -->
    <template v-slot:quote-modals>
      <template>
        <purchase-order-st1-dialog
          :show-modal="isModalOpenedPurchaseOrderSt1"
          :data="user"
          :btn-text="$t('Next')"
          @formsubmitted="switchStep2PurchaseModal"
        ></purchase-order-st1-dialog>
        <purchase-order-st2-dialog
          :show-modal="isModalOpenedPurchaseOrderSt2"
          :data="user"
          :btn-text="$t('Sign and send')"
          @formsubmitted="$emit('purchaseorder')"
        ></purchase-order-st2-dialog>
      </template>
    </template>
  </list-item>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import OneItem from "@/components/OrderUsersList/OneUserItem";
import PurchaseOrderSt1 from "@/components/ContractModals/Modals/PurchaseOrderSt1";
import PurchaseOrderSt2 from "@/components/ContractModals/Modals/PurchaseOrderSt2";
import {
  getQuotationPDF,
  getQuoteRequestPDF,
  getPurchaseOrderPDF,
  getServiceReportPDF,
  replaceContractor,
  declineUnsignedPO
} from "@/api";
export default {
  name: "ItemActions",
  props: {
    mode: String,
    user: Object,
    ticketData: Object,
    selectionComplete: Boolean
  },
  components: {
    "list-item": OneItem,
    "purchase-order-st1-dialog": PurchaseOrderSt1,
    "purchase-order-st2-dialog": PurchaseOrderSt2
  },
  data() {
    return {
      apiUrl: `${process.env.VUE_APP_API_URL}/api/v1`,
      orderId: this.$route.params["id"]
    };
  },
  computed: {
    ...mapState("PurchaseOrderSt1ContractModal", {
      isModalOpenedPurchaseOrderSt1: state => state.isModalOpened
    }),
    ...mapState("PurchaseOrderSt2ContractModal", {
      isModalOpenedPurchaseOrderSt2: state => state.isModalOpened
    }),
    ticketState() {
      return this.ticketData.state;
    },
    isOnWarranty() {
      return this.ticketState === "on_warranty";
    },
    showSecondLayer() {
      return this.mode === "one";
    },
    showThirdLayer() {
      return this.ticketState === "done";
    }
  },
  methods: {
    ...mapActions("UsersListsStore", [
      "getQuotationForTicket",
      "sendSelectUser",
      "sendReplaceUser"
    ]),
    ...mapMutations({
      // step 1
      openPurchaseStep1Modal: "PurchaseOrderSt1ContractModal/openModal",
      closePurchaseStep1Modal: "PurchaseOrderSt1ContractModal/closeModal",
      // step 2
      openPurchaseStep2Modal: "PurchaseOrderSt2ContractModal/openModal"
    }),
    switchStep2PurchaseModal() {
      this.closePurchaseStep1Modal();
      this.openPurchaseStep2Modal();
    },
    selectUser(id) {
      this.sendSelectUser(id)
        .then(this.refetchData)
        .catch(e => {
          console.error(e);
        });
    },
    replaceUser(qid) {
      replaceContractor(qid, this.$route.params["id"])
        .then(this.refetchData)
        .catch(e => {
          console.error(e);
        });
    },
    refetchData() {
      this.getQuotationForTicket(this.orderId).catch(e => {
        console.error(e);
      });
    },

    viewQuotationRequest(qid) {
      getQuoteRequestPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewQuotation(qid) {
      getQuotationPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewPurchaseOrder(qid) {
      getPurchaseOrderPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    viewServiceReport(qid) {
      getServiceReportPDF(qid)
        .then(r => {
          const file = new Blob([r.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch(e => {
          console.error(e);
        });
    },
    withdrawPO(qid) {
      declineUnsignedPO(qid)
        .then(() => {
          this.$router.push({ name: "Quotations" });
        })
        .catch(e => {
          console.error(e);
          this.errorDialog = true;
          this.invoiceError = e.response.data.error || e.response.data.errors.join("\n");
        });
    }
  }
};
</script>
