<script>
import { mapGetters } from "vuex";
import BaseUsersListSection from "@/components/OrderUsersList/BaseUsersListSection";

export default {
  extends: BaseUsersListSection,
  name: "SelectedList",
  computed: {
    ...mapGetters("UsersListsStore", ["selectedQuotes"]),
    quotations() {
      return this.selectedQuotes;
    }
  }
};
</script>
