<template>
  <section class="multi-users-list">
    <quotations-list
      v-if="showQuotationsList"
      :title="$t('Quotations')"
      :ticket-data="ticketData"
      @purchaseorder="$emit('purchaseorder')"
    ></quotations-list>

    <selected-list
      v-if="showSelectedList"
      :title="$t('Select new contractor from the list above')"
      :ticket-data="ticketData"
    ></selected-list>

    <contractors-list
      v-if="showContractorsList"
      :title="$t('Contractors')"
      :ticket-data="ticketData"
    ></contractors-list>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import QuotationsList from "@/components/OrderUsersList/ListsSpecific/QuotationsList";
import SelectedList from "@/components/OrderUsersList/ListsSpecific/SelectedList";
import ContractorsList from "@/components/OrderUsersList/ListsSpecific/ContractorsList";

export default {
  name: "multiple-users-list",
  props: {
    ticketData: Object
  },
  components: {
    "quotations-list": QuotationsList,
    "selected-list": SelectedList,
    "contractors-list": ContractorsList
  },
  computed: {
    ...mapGetters("UsersListsStore", ["selectedQuotes"]),
    ticketState() {
      return this.ticketData.state;
    },
    showQuotationsList() {
      return this.ticketState === "quoted";
    },
    showSelectedList() {
      return this.selectedQuotes.length;
    },
    showContractorsList() {
      return (
        this.ticketState === "in_progress" ||
        this.ticketState === "done" ||
        this.ticketState === "on_warranty" ||
        this.ticketState === "warranty_requested"
      );
    }
  }
};
</script>

<style scoped>
.multi-users-list {
  display: block;
  width: 100%;
}
</style>
