import { mapState, mapActions } from "vuex";
import ImagesGallery from "@/components/ImagesGallery.vue";
import AdditionalConditions from "@/components/AdditionalConditions.vue";
import MultipleUsersList from "@/components/OrderUsersList/MultipleUsersList.vue";
import RepairTypeIco from "@/components/RepairTypeIco.vue";
import ErrorsSnackbar from "@/components/ErrorsSnackbar.vue";

import { archiveOrder } from "@/api";
import moment from "moment";

export default {
  components: {
    ImagesGallery,
    AdditionalConditions,
    MultipleUsersList,
    RepairTypeIco,
    ErrorsSnackbar
  },
  data: function() {
    return {
      // ticket: {
      //   id: "id",
      //   subject_description: {
      //     subject_info: {
      //       brand: {},
      //       manufacturer: {}
      //     }
      //   },
      //   object_address: {},
      //   subject_attachments: {},
      //   urgency: {}
      // },
      showPurchaseOrderErrors: false,
      purchaseOrderErrors: [],
      currentMoment: moment()
    };
  },
  mounted() {
    this.fetchData();
    setInterval(() => {
      this.currentMoment = moment();
    }, 1000);
  },
  computed: {
    ...mapState("PurchaseOrderSt2ContractModal", ["termsChecked", "quoteId"]),
    ticket() {
      return this.$store.state.ticket || {};
    },
    created_date() {
      return moment(this.ticket.created_at).format("DD/MM/YYYY HH:mm");
    },
    validTo() {
      if (!this.ticket.urgency) return;
      const endAt = moment(this.ticket.created_at).add(
        Number(this.ticket.urgency.date_value),
        this.getOrderValidPeriodName()
      );
      if (endAt && this.currentMoment) {
        const duration = moment.duration(
          endAt.valueOf() - this.currentMoment.valueOf(),
          "milliseconds"
        );
        let message = "";

        if (duration.months() > 0) {
          message += duration.months() + " months ";
        }
        if (duration.days() > 0) {
          message += duration.days() + " days ";
        }

        return message + duration.hours() + ":" + duration.minutes() + ":" + duration.seconds();
      }
    },
    repairType() {
      const typesList = {
        maintance_repair: "Maintenance repair",
        major_overhaul_repair: "Major overhaul",
        upgrade_and_retrofit: "Upgrade and retrofit"
      };
      const typeId = this.ticket.subject_description.repair_type_name;
      return typeId !== null ? typesList[typeId] : null;
    },
    hasManufacture() {
      return (
        this.hasObjectInInfo("manufacturer") &&
        this.ticket.subject_description.subject_info.manufacturer !== null
      );
    },
    hasBrand() {
      return (
        this.hasObjectInInfo("brand") && this.ticket.subject_description.subject_info.brand !== null
      );
    }
  },
  methods: {
    ...mapActions("PurchaseOrderSt2ContractModal", ["setPurchaseOrderData"]),
    fetchData() {
      this.getTicket();
      this.getServicesList();
      this.getAdditionalConditionsList();
    },

    getOrderValidPeriodName: function() {
      return this.ticket.urgency.date_item + "s";
    },

    getTicket: function() {
      this.$store
        .dispatch("getOneOrderTicket", this.$route.params["id"])
        .then(() => {
          if (this.ticket.quote_request && this.ticket.quote_request.quote_request_id) {
            this.$router.push({
              name: "Quotation",
              params: { qid: this.ticket.quote_request.quote_request_id }
            });
          }
        })
        .catch(() => {
          this.$router.push({ name: "SearchExpert" });
        });
    },
    getAdditionalConditionsList() {
      if (!this.$store.state.additionalConditions.length) {
        this.$store.dispatch("getAdditionalConditions");
      }
    },
    getServicesList() {
      if (!this.$store.state.servicesList.length) {
        this.$store.dispatch("getServicesList");
      }
    },
    cancelOrder() {
      archiveOrder(this.$route.params["id"])
        .then(() => {
          this.$router.push({
            name: "ProjectsOrdersNew"
          });
        })
        .catch(e => {
          this.showPurchaseOrderErrors = true;
          this.purchaseOrderErrors = e.response.data.errors[0];
        });
    },
    hasObjectInTicket(key) {
      return Object.hasOwnProperty.call(this.ticket, key);
    },
    hasObjectInDescription(key) {
      return Object.hasOwnProperty.call(this.ticket.subject_description, key);
    },
    hasObjectInInfo(key) {
      return (
        this.hasObjectInDescription("subject_info") &&
        Object.hasOwnProperty.call(this.ticket.subject_description.subject_info, key)
      );
    }
  }
};
