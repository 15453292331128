<template>
  <section class="base-users-list">
    <h3 class="base-users-list__title" v-html="title"></h3>
    <div class="base-users-list__content">
      <users-list :quotations="quotations" :ticket-data="ticketData"></users-list>
    </div>
  </section>
</template>

<script>
import UsersList from "@/components/OrderUsersList/UsersList.vue";

export default {
  name: "base-users-list",
  props: {
    title: String,
    ticketData: Object
  },
  components: {
    "users-list": UsersList
  },
  computed: {
    quotations() {
      return [];
    }
  }
};
</script>

<style scoped>
.base-users-list {
  display: block;
  width: 100%;
}
.base-users-list__title {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: normal;
  border-bottom: 1px solid #dfdfdf;
}
.base-users-list__content {
  display: block;
  width: 100%;
  margin-bottom: 60px;
  padding: 10px;
}
</style>
