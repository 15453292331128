<script>
import { mapGetters } from "vuex";
import BaseUsersListSection from "@/components/OrderUsersList/BaseUsersListSection";

export default {
  extends: BaseUsersListSection,
  name: "ContractorsList",
  computed: {
    ...mapGetters("UsersListsStore", ["contractors"]),
    quotations() {
      return this.contractors;
    }
  }
};
</script>
